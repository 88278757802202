import { DSGrid, DSGridBlock, GridSurfaceColour } from '@/components/ds/Grid/Grid'
import React from 'react'
import { ButtonBlock } from '../ButtonBlock'
import { AccordionBlock } from './Accordion'
import { TypographyBlock } from './Typography'
import { SimpleImageBlock } from './SimpleImage'
import { SpacerBlock } from './Spacer'
import { DsButtonBlock } from './DsButtonBlock'
import { ResponsiveGridBlock } from './ResponsiveGridBlock'
import { ImageGalleryBlock } from './ImageGallery'
import { D30IngredientListBlock } from '@/components/pages/daily30/blocks/D30IngredientListBlock'
import { D30GalleryBlock } from '@/components/pages/daily30/blocks/D30GalleryBlock'
import { FragmentComponent } from '@/types/graphql'
import { D30TrustpilotBlock } from '@/components/pages/daily30/blocks/TrustpilotBlock'
import { ImageCarouselBlock } from '@/components/blocks/ImageCarouselBlock'
import { VideoGroup } from '@/blocks/VideoGroup'
import { HomepageScienceIconGrid } from '@/components/pages/homepage2024/content/science/HomepageScienceIconGrid'
import { D30AvailabilityCheckerBlock } from '@/components/pages/daily30/blocks/D30AvailabilityCheckerBlock'
import { D30FeatureListBlock } from '@/components/pages/daily30/blocks/D30FeatureListBlock'
import { DividerBlock } from '@/blocks/DesignSystem/DividerBlock'
import { useSetVisibility } from '@/lib/hooks/useSetVisibility'
import { LabelImage } from '@/blocks/LabelImage'
import { CarouselBlock } from '../CarouselBlock'
import { MembershipPlanBlock } from '@/components/pages/membership/plans/MembershipPlanBlock'
import { Tabs } from './Tabs'
import { IconGridBlock } from './IconGrid'

export type GridBlockRecord = {
  __typename: 'GridBlockRecord'
  id: string
  content: any
  config: any
  backgroundColor?: GridSurfaceColour
  mobileBackgroundColor?: GridSurfaceColour
  rounded?: boolean
  textAlign?: 'center' | 'left' | 'right'
  padded?: boolean
  visibility?: string[]
  wrapInGrid?: boolean
  dataTestId?: string
}

const fragment = `
  fragment GridBlockFragment on GridBlockRecord {
    __typename
    id
    content {
      ...AccordionBlockFragment
      ...SimpleImageBlockFragment
      ...TypographyBlockFragment
      ...DsButtonBlockFragment
      ...ResponsiveGridBlockFragment
      ...ImageGalleryBlockFragment
      ...SpacerBlockFragment
      ...D30IngredientListBlockFragment
      ...D30GalleryBlockFragment
      ...D30TrustpilotBlockFragment
      ...ImageCarouselBlockFragment
      ...VideoGroupFragment
      ...HomepageScienceIconGridFragment
      ...DividerBlockFragment
      ...LabelImageFragment
      ...CarouselBlockFragment
      ...MembershipPlanFragment
      ...TabFragment
      ...IconGridBlockFragment
    }
    backgroundColor
    mobileBackgroundColor
    rounded
    textAlign
    config
    padded
    visibility
    wrapInGrid
    dataTestId
  }

  ${AccordionBlock.fragment}
  ${SimpleImageBlock.fragment}
  ${TypographyBlock.fragment}
  ${DsButtonBlock.fragment}
  ${ResponsiveGridBlock.fragment}
  ${ImageGalleryBlock.fragment}
  ${ImageCarouselBlock.fragment}
  ${VideoGroup.fragment}
  ${HomepageScienceIconGrid.fragment}
  ${D30IngredientListBlock.fragment}
  ${D30GalleryBlock.fragment}
  ${D30AvailabilityCheckerBlock.fragment}
  ${D30FeatureListBlock.fragment}
  ${D30TrustpilotBlock.fragment}
  ${DividerBlock.fragment}
  ${LabelImage.fragment}
  ${CarouselBlock.fragment}
  ${MembershipPlanBlock.fragment}
  ${Tabs.fragment}
  ${IconGridBlock.fragment}
`

const renderBlock = (record) => {
  switch (record.__typename) {
    case 'ButtonRecord':
      return <ButtonBlock key={record.id} record={record} />
    case 'AccordionRecord':
      return <AccordionBlock key={record.id} record={record} />
    case 'TypographyRecord':
      return <TypographyBlock key={record.id} record={record} />
    case 'SimpleImageRecord':
      return <SimpleImageBlock key={record.id} record={record} />
    case 'SpacerRecord':
      return <SpacerBlock key={record.id} record={record} />
    case 'DividerRecord':
      return <DividerBlock key={record.id} record={record} />
    case 'DsButtonRecord':
      return <DsButtonBlock key={record.id} record={record} />
    case 'ResponsiveGridBlockRecord':
      return <ResponsiveGridBlock key={record.id} record={record} />
    case 'ImageGalleryRecord':
      return <ImageGalleryBlock key={record.id} record={record} />
    case 'D30IngredientListRecord':
      return <D30IngredientListBlock key={record.id} />
    case 'D30GalleryRecord':
      return <D30GalleryBlock key={record.id} record={record} />
    case 'D30TrustpilotRecord':
      return <D30TrustpilotBlock key={record.id} record={record} />
    case 'ImageCarouselRecord':
      return <ImageCarouselBlock key={record.id} record={record} />
    case 'VideoGroupRecord':
      return <VideoGroup key={record.id} record={record} />
    case 'HomepageScienceIconGridRecord':
      return <HomepageScienceIconGrid key={record.id} record={record} />
    case 'LabelImageRecord':
      return <LabelImage key={record.id} record={record} />
    case 'CarouselRecord':
      return <CarouselBlock key={record.id} record={record} />
    case 'MembershipPlanRecord':
      return <MembershipPlanBlock key={record.id} record={record} />
    case 'TabRecord':
      return <Tabs key={record.id} record={record} />
    case 'IconGridRecord':
      return <IconGridBlock key={record.id} record={record} />
  }
}

const GridBlockRecordComponent = ({ record }) => (
  <DSGridBlock
    breakConfig={record?.config}
    backgroundColor={record?.backgroundColor}
    mobileBackgroundColor={record?.mobileBackgroundColor}
    rounded={record?.rounded}
    textAlign={record?.textAlign}
    padded={record?.padded}
    dataTestId={record?.dataTestId}
  >
    {record?.content?.map((record) => renderBlock(record))}
  </DSGridBlock>
)

export const GridBlock: FragmentComponent<{}, GridBlockRecord> = ({ record }) => {
  const isVisible = useSetVisibility(record?.visibility ?? [])
  const shouldWrapInGrid = record?.wrapInGrid ?? false
  return isVisible ? (
    shouldWrapInGrid ? (
      <DSGrid
        backgroundColor={record?.backgroundColor}
        mobileBackgroundColor={record?.mobileBackgroundColor}
        backgroundFullWidth
      >
        <GridBlockRecordComponent record={record} />
      </DSGrid>
    ) : (
      <GridBlockRecordComponent record={record} />
    )
  ) : null
}

GridBlock.fragment = fragment
GridBlock.recordName = 'GridBlockRecord'
