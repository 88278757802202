import { GetStaticProps } from 'next'
import { getHomePageContent } from '@/graphql/Homepage'
import { Homepage2024 } from '@/components/pages/homepage2024/Homepage2024'

export const getStaticProps: GetStaticProps = async ({ preview }) => {
  const page = await getHomePageContent(!!preview)

  // When running experiments in the homepage with Kameleoon:
  // Uncomment the following lines and export HomePageExperiment instead of Homepage
  // and add pageVariantB and pageVariantC to the props
  // const pageVariantB = await getHomePageContentVariantB(!!preview)
  // const pageVariantC = await getHomePageContentVariantC(!!preview)

  if (page) {
    return {
      props: { page },
    }
  }

  throw new Error('No Homepage content found')
}

export default Homepage2024
