import { DSDivider, DSFlex, DSIcon, DSSpacer, DSText } from '@zoe/ds-web'
import { formatCurrency } from '@/lib/checkout/utils'
import { Product } from '@/lib/checkout/types'
import { trackCtaClick } from '@/lib/gtm/gtmV2'
import { DSLinkButton } from '@/components/ds/LinkButton/LinkButton'

type PlanCardPriceSectionProps = {
  kitPrice: Product | undefined
  membershipPrice: Product | undefined
  kitLabel: string
  membershipLabel: string
  subscriptionLabel: string
  planId: string
  ctaLabel?: string
  ctaAriaLabel?: string
}

export const PlanCardPriceSection = ({
  kitPrice,
  membershipPrice,
  kitLabel,
  membershipLabel,
  subscriptionLabel,
  planId,
  ctaLabel,
  ctaAriaLabel,
}: PlanCardPriceSectionProps) => {
  if (!kitPrice || !membershipPrice) {
    return null
  }

  const label = ctaLabel || `Choose '${planId}'`

  const onClickHandler = () => {
    trackCtaClick('plans', `${label}|`)
  }

  const membershipPriceParts = (membershipPrice.price / 100).toFixed(2).split('.')
  return (
    <>
      <DSFlex direction="row" gap={12} justifyContent="center" alignItems="center">
        <div>
          <DSText variant="fluid-eyebrow-100" as="p">
            {kitLabel}
          </DSText>
          <DSSpacer size={4} direction="vertical" />
          <DSText variant="fluid-heading-800" as="p">
            {formatCurrency(kitPrice.currency, kitPrice.price, true)}
          </DSText>
        </div>
        <div>
          <DSSpacer size={32} direction="vertical" />
          <DSIcon name="plus" size={16} />
        </div>
        <div>
          <DSText variant="fluid-eyebrow-100" as="p">
            {membershipLabel}
          </DSText>
          <DSSpacer size={4} direction="vertical" />
          <DSFlex direction="row" alignItems="flex-end">
            <DSText variant="fluid-heading-800" as="p">
              {formatCurrency(membershipPrice.currency, parseInt(membershipPriceParts[0]) * 100, true)}
            </DSText>
            <DSSpacer size={4} direction="horizontal" />
            <DSFlex direction="column">
              <DSText variant="fluid-paragraph-300" as="p">
                .{membershipPriceParts[1]}
              </DSText>
              <DSText variant="fluid-label-100" as="span" colour="secondary">
                {subscriptionLabel}
              </DSText>
            </DSFlex>
          </DSFlex>
        </div>
      </DSFlex>

      <DSSpacer size={16} direction="vertical" />
      <DSDivider />
      <DSSpacer size={16} direction="vertical" />

      <DSLinkButton
        variant={'primary'}
        colourScheme={'default'}
        size={'large'}
        width={'full'}
        onPress={onClickHandler}
        href={`/checkout/v2?source=membershippdp&product=${kitPrice.zoeProductId}-${membershipPrice.zoeProductId}`}
        testId={`membership-price-card-button-${kitPrice.zoeProductId}`}
        aria-label={ctaAriaLabel || label}
      >
        <DSText variant="fluid-paragraph-200" as="p" weight="lotaSemiBold" align="center">
          {label}
        </DSText>
      </DSLinkButton>
    </>
  )
}
