import { HomePageContentBlocks } from '@/types/homepage'
import { Grid } from '@/components/blocks/DesignSystem/Grid'
import { GridBlock } from '@/components/blocks/DesignSystem/GridBlock'
import { SpacerBlock } from '@/components/blocks/DesignSystem/Spacer'
import { ImageBentoGrid } from '../../blocks/DesignSystem/ImageBentoGrid'
import { DSSpacer } from '@zoe/ds-web'
import { VideoCoverBlock } from '@/components/blocks/DesignSystem/VideoCoverBlock'

const renderBlock = (record, index) => {
  const key = `${record.id}-${index}}`
  switch (record.__typename) {
    case 'GridRecord':
      return <Grid key={key} record={record} />
    case 'GridBlockRecord':
      return <GridBlock key={key} record={record} />
    case 'SpacerRecord':
      return <SpacerBlock key={key} record={record} />
    case 'ImageBentoGridRecord':
      return <ImageBentoGrid key={key} record={record} />
    case 'VideoCoverRecord':
      return <VideoCoverBlock key={key} record={record} />
  }
}

type HomePageContentProps = {
  content: HomePageContentBlocks[]
}

export const HomePageContent2024 = ({ content }: HomePageContentProps) => {
  return (
    <>
      <DSSpacer direction="vertical" size={72} />
      <DSSpacer direction="vertical" size={12} breakAt={{ m: 0 }} />
      {(content ?? []).map((record, index) => renderBlock(record, index))}
    </>
  )
}
