import { DSBadge, DSFlex, DSSpacer, DSText } from '@zoe/ds-web'
import styled from 'styled-components'
import { PlanCardFeatureGroup } from './PlanCardFeatureGroup'
import {
  PlanCardHeaderBackgroundColor,
  PlanFeatureGroupBlock,
  PlanPriceSectionBlock,
} from '@/components/pages/membership/plans/PlanCardBlock'
import { PlanCardPriceSection } from './PlanCardPriceSection'
import { useProducts } from '@/components/pages/membership/ProductsContext'
import { useCheckoutCountry } from '@/lib/hooks/useCheckoutCountry'
import { getCurrentPrices } from './getCurrentPrices'
import { useEffect, useState } from 'react'
import { Product } from '@/lib/checkout/types'
import { formatCurrency } from '@/lib/checkout/utils'
import { processTypographyMarkdown } from '@/components/blocks/DesignSystem/Typography'

const StyledCard = styled.div`
  border-radius: var(--radius-16);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  min-width: 320px;
  max-width: 320px;
  overflow: hidden;
  background-color: var(--colour-surface-neutral);
`

const StyledHeader = styled.div<{ $backgroundColour?: PlanCardHeaderBackgroundColor }>`
  padding: 0 var(--grid-16);
  ${({ $backgroundColour }) => `background-color: var(--colour-surface-${$backgroundColour})`};
`

const StyledContent = styled.div`
  padding: 0 var(--grid-16);
`

type PlanCardProps = {
  id?: string
  title: string
  tagline?: string
  badge?: string
  featureGroups?: PlanFeatureGroupBlock[]
  priceSection?: PlanPriceSectionBlock
  footnote?: string
  headerBackgroundColor?: PlanCardHeaderBackgroundColor
}

export const PlanCard = ({
  id,
  title,
  tagline,
  headerBackgroundColor,
  badge,
  featureGroups,
  priceSection,
  footnote,
}: PlanCardProps) => {
  const products = useProducts()
  const { checkoutCountry } = useCheckoutCountry()
  const { kits, memberships } = getCurrentPrices(products)
  const [kitPrice, setKitPrice] = useState<Product | undefined>(undefined)
  const [memberhipPrice, setMemberhipPrice] = useState<Product | undefined>(undefined)
  const [totalCost, setTotalCost] = useState<number | undefined>(undefined)
  const [costPerDay, setCostPerDay] = useState<number | undefined>(undefined)

  useEffect(() => {
    if (priceSection?.planId && kits && memberships && checkoutCountry) {
      setKitPrice(kits[priceSection?.planId][checkoutCountry])
      setMemberhipPrice(memberships[priceSection?.planId][checkoutCountry])
    }
  }, [kits, memberships, checkoutCountry, priceSection])

  useEffect(() => {
    if (memberhipPrice && kitPrice) {
      if (memberhipPrice.membershipCommitmentMonths) {
        const total = memberhipPrice.price * memberhipPrice.membershipCommitmentMonths + kitPrice.price
        setTotalCost(total)
        const days =
          memberhipPrice.membershipCommitmentMonths === 12
            ? 365
            : (memberhipPrice.membershipCommitmentMonths * 365) / 12
        setCostPerDay(Math.floor(total / days))
      }
    }
  }, [kitPrice, memberhipPrice])
  return (
    <StyledCard id={id}>
      <StyledHeader $backgroundColour={headerBackgroundColor}>
        <DSSpacer size={16} direction="vertical" />
        <DSFlex direction="row" justifyContent="space-between" alignItems="flex-start">
          <DSText
            variant="fluid-heading-600"
            as="h4"
            weight="nantesItalic"
            colour={headerBackgroundColor === 'navy-bold' ? 'primary-inverse' : 'primary'}
          >
            {title}
          </DSText>
          {badge && (
            <DSBadge size="regular" surfaceColor="navy-bold" textColor="primary-inverse">
              {badge}
            </DSBadge>
          )}
        </DSFlex>
        <DSSpacer size={12} direction="vertical" />
        <DSBadge size="large" surfaceColor="neutral" textColor="primary">
          {tagline}
        </DSBadge>
        <DSSpacer size={16} direction="vertical" />
      </StyledHeader>
      <StyledContent>
        {featureGroups?.map((group, index) => (
          <PlanCardFeatureGroup key={index} title={group.title} features={group.features} />
        ))}
        <DSSpacer size={16} direction="vertical" />
        {priceSection && (
          <>
            <PlanCardPriceSection
              kitLabel="Testing"
              kitPrice={kitPrice}
              membershipLabel="Membership"
              membershipPrice={memberhipPrice}
              planId={priceSection?.planId}
              subscriptionLabel="/month"
              ctaLabel={priceSection?.ctaLabel}
              ctaAriaLabel={priceSection?.ctaAriaLabel}
            />
          </>
        )}
        <DSSpacer size={16} direction="vertical" />
        {footnote ? (
          <DSText variant="fluid-label-100" as="p" weight="lotaLight" align="center">
            {processTypographyMarkdown({
              content: footnote,
              variant: 'fluid-label-100',
              weight: 'lotaLight',
              colour: 'primary',
            })}
          </DSText>
        ) : (
          totalCost &&
          costPerDay &&
          kitPrice && (
            <DSText variant={'fluid-label-100'} weight="lotaLight" align="center">
              Total cost {formatCurrency(kitPrice.currency, totalCost, true)},{' '}
              <DSText variant={'fluid-label-100'} weight="lotaRegular" as="span">
                equal to {formatCurrency(kitPrice.currency, costPerDay, true)}/day
              </DSText>
            </DSText>
          )
        )}
        <DSSpacer size={16} direction="vertical" />
      </StyledContent>
    </StyledCard>
  )
}
